<template>
    <el-popover
      :popper-class="'userInfoPopover'"
      placement="top"
      trigger="click"
      @show="onShow"
      @hide="onHide"
    >
      <div class="flex-column">
        <div class="title-wrapper flexrow-column-center">
          <span class="font-size-16" style="color:black">项目数据</span>
        </div>
        <div class="center-wrapper">
          <template v-for="(item,index) in centerData">
            <div class="center-once-wrapper flex-row" v-bind:key="'centerData'+index" >
              <div class="icon-bg flex-all-center" 
                :style="{'background': 'rgba(' + item.bgColor[0] +','+ item.bgColor[1] +','+item.bgColor[2] + ', .1)'}">
             
                <span :class="['iconfont',item.icon,'font-size-22']" :style="{'color':item.iconColor}"></span>
              </div>
              <span class="font-size-16" style="color:black">{{item.value}}</span>
            </div>
          </template>
        </div>
        <div class="down-wrapper flex-row-space-between">
          <template v-for="(item,index) in downData">
            <div class="down-once-wrapper flex-row" v-bind:key="'downData'+index" >
              <div class="point-wrapper" :style="{'background':item.bgColor}"></div>
              <span class="font-size-16 font-opacity-45" style="color:#00000073">{{item.des}}</span>
              <span class="font-size-16" style="color:black">{{item.value}}</span>
            </div>
          </template>
        </div>
      </div>
      <span class="font-size-14 pointer" 
            style="color:#3468FE"
            slot="reference"
            @click="onClickProjectDetail">数据</span>
    </el-popover>
</template>

<script>
export default {
  name: "projectDataPop",
  props: {
    pitem:Object,
    data:Object
  },
  data() {
    return {
      isShow:false,
      centerData:[{bgColor:[52, 104, 254], iconColor:"#3468FE", value:0,icon:'iconshipin'},
          {bgColor:[255, 153, 0], iconColor:"#FFA925", value:0,icon:'iconmedia_image_fill'},
          {bgColor:[49, 207, 208], iconColor:"#31CFD0", value:0,icon:'iconfile_file-text_fill'},
          {bgColor:[88, 103, 195], iconColor:"#5867C3", value:0,icon:'iconbiaoge'},
          {bgColor:[88, 103, 195], iconColor:"#5867C3", value:0,icon:'icongengduo1'}],
      downData:[{bgColor:"#3468FE", des:"评论次数：", value:0},
          {bgColor:"#FFA925", des:"分享次数：", value:0},
          {bgColor:"#31CFD0", des:"下载次数：", value:0},
          {bgColor:"#5867C3", des:"上传次数：", value:0}],
    };
  },
  watch:{
    data(){
      this.centerData[0].value = this.data.video_count;
      this.centerData[1].value = this.data.image_count;
      this.centerData[2].value = this.data.doc_count;
      this.centerData[3].value = this.data.excel_count;
      this.centerData[4].value = this.data.other_count;
      this.downData[0].value = this.data.comment;
      this.downData[1].value = this.data.share;
      this.downData[2].value = this.data.download;
      this.downData[3].value = this.data.upload;
    }
  },
  methods: {
    onClickProjectDetail(){
      if(!this.isShow){
        this.$emit('onClickProjectDetail', this.pitem, this.closePop);
      }
    },
    onShow(){
      this.isShow = true;
    },
    onHide(){
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.title-wrapper{
  width:100%;
  height:40px;
  border-bottom: 1px solid #E8E8E8;
}
.center-wrapper{
  margin-top: 10px;
  margin-bottom: 10px;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #E8E8E8;
  .center-once-wrapper{
    width:30%;
    height:50px;
    margin-bottom: 20px;
    .icon-bg{
      width: 50px;
      height: 50px;
      border-radius: 8px;
      margin-right:8px;
    }
  }
}
.down-wrapper{
  width:100%;
  flex-wrap: wrap;
   margin-top: 14px;
  .down-once-wrapper{
    width:50%;
    margin-bottom:12px;
    .point-wrapper{
      width:10px;
      height:10px;
      margin-right:10px;
      border-radius:50%;
    }
  }
}

</style>
