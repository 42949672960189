<template>
  <el-dialog title="编辑" :visible="true" width="36%" :before-close="handleClose">
    <el-form :model="form" ref="projectEditForm" :rules="rules">
      <el-form-item label="项目名称：" :label-width="formLabelWidth" prop="name">
        <el-input class="search-input-dialog" v-model="form.name" autocomplete="off" ></el-input>
      </el-form-item>
      <el-form-item label="所有者：" :label-width="formLabelWidth" prop="region">
        <el-select v-model="form.region" filterable  placeholder="请选择所有者"  class="select-number">
          <template v-for="(item) in options">
            <el-option v-if="item.key !== ''" :label="item.value" :value="item.key" :key="item.key"></el-option>
          </template>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" class="buttonColor" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    options: Array,
    projectName: String,
    projectkey: String
  },
  data () {
    return {

      dialogFormVisible: false,
      form: {
        name: this.projectName,
        region: this.projectkey || ''
      },
      formLabelWidth: '120px',
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 32,
            message: '长度在 2 到 32 之间',
            trigger: 'blur'
          }
        ],
        region: [
          {
            required: true,
            message: '请选择',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('onCancel')
    },
    onCancel () {
      this.$emit('onCancel')
    },
    onConfirm () {
      this.$refs.projectEditForm.validate((valid) => {
        if (valid) {
          this.$emit('onConfirm', this.form)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-pagination__editor.el-input .el-input__inner{
width: 370px;
}
.el-select{
  .el-input--suffix{
    // width: 23.14rem;
  }
}
</style>
