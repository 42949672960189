<template>
  <div class="top-item-wrapper flexrow-column-center">
    <div class="icon-bg flex-all-center" 
      :style="{'background': 'rgba(' + data.bgColor[0] +','+ data.bgColor[1] +','+data.bgColor[2] + ', .1)'}">
      <span  :class="['iconfont',data.icon,'font-size-22']" 
      :style="{'color':data.iconColor}"></span>
    </div>
    <div class="top-item-content-wrapper flex-column">
      <span class="font-size-26" style="color:black;margin-bottom:0.5rem">{{data.value}}</span>
      <span class="font-size-14" style="color:rgba(0, 0, 0, .45)">{{data.des}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopItem",
  props:{
    data:Object
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  components:{
    
  }
};
</script>

<style lang="scss" scoped>
.top-item-wrapper{
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
  position: relative;
  .icon-bg{
    position: absolute;
    left: 30px;
    top:50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
  .top-item-content-wrapper{
    position: absolute;
    left: 104px;
    top:50%;
    transform: translateY(-50%);
    height: 50px;
  }
}
</style>