<template>
  <div class="pagination-list-wrapper flexcolumn-row-center">
    <div class="top-title-wrapper flex-row" 
      :class="{border: type==='flow'}">
      <template v-for="(item) in title">
        <span class="font-size-14 title-text">{{item.value}}</span>
      </template>
      <span v-if="isNeedOperation.length > 0"
        class="font-size-14 title-text">操作</span>
    </div>
    <template v-for="(item) in curList">
      <div class="content-wrapper flex-row" 
      :class="{border: type==='flow'}">
        <template v-for="(title) in title">
          <template v-if="title.key === 'obj'">
            <template v-if="type === 'project'">
              <div class="flex-column content-text">
                <span class="font-size-16 font-opacity-65">{{item[title.key].name}}</span>
                <div class="font-size-14 font-opacity-45" style="margin-top:0.5rem">
                  所有者：<span v-text="item[title.key].user_name"> </span>
                  {{' 成员：'+item[title.key].users}}
                </div>
              </div>
            </template>
            <template v-else-if="type === 'shoot'">
              <div class="flex-column content-text">
                <span class="font-size-16 font-opacity-65">{{item[title.key].shoot_name}}</span>
                <span class="font-size-14 font-opacity-45" style="margin-top:0.5rem">{{'所有者：'+item[title.key].user_name+' 成员：'+item[title.key].users}}</span>
              </div>
            </template>
          </template>
          <span v-else class="font-size-16 content-text" v-html="item[title.key]"></span>
        </template>
        <div v-if="isNeedOperation.length > 0" class="content-text">
          <ProjectDataPop v-if="isNeedOperation[0] === 1" style="margin-right:1rem;"
            :pitem='item'
            :data='projectDetail'
            @onClickProjectDetail='onClickProjectDetail'/>
          <span v-if="isNeedOperation[1] === 1" class="font-size-16 pointer" 
            style="margin-right:1rem;color:#3468FE" 
            @click="onClickOperation(1,item)">编辑</span>
          <span v-if="isNeedOperation[2] === 1" class="font-size-16 pointer" 
            style="color:#ED4014" 
            @click="onClickOperation(2,item)">删除</span>
        </div>
      </div>
    </template>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="curPage"
      :page-size="pageSize"
      :page-sizes="[pageSize]"
      layout="prev, pager, next, sizes, jumper , total"
      :background="true"
      :total="list.length">
    </el-pagination>
  </div>
</template>

<script>
import ProjectDataPop from "./ProjectDataPop"
export default {
  name: "PaginationList",
  props: {
    type:String,
    title:Array,
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
    totalLength: {
      type: Number,
      default: 0
    },
    isNeedOperation: {
      type: Array,
      default: function() {
        return []
      }
    },
    pageSize:{
      type:Number,
      default: 10, //默认显示10条
    },
    projectDetail:Object
  },
  data() {
    return {
      curPage:1,
      curList:[],
    };
  },
  watch:{
    list(){
      let page = Math.floor(this.list.length / this.pageSize) + 1;
      if(this.list.length % this.pageSize == 0){
        page--;
      }
      if(this.curPage > 1 && this.curPage > page){
        this.curPage = page;
      }
      this.computeList();
    }
  },
  methods: {
    computeList(){
      let start = (this.curPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      this.curList = this.list.slice(start,end);
    },
    onClickOperation(type,item){
      if(this.type == 'project'){
        this.$emit('onOperation',{type,subData:item});
      }else{
        this.$emit('onOperation',{type,subData:item});
      }
    },
    handleCurrentChange(page){
      this.curPage = page;
      this.computeList();
    },
    onClickProjectDetail(item, cb){
      this.$emit('onOperation',{type:0, subData:item, cb});
    }
  },
  components:{
    ProjectDataPop
  }
};
</script>

<style lang="scss" scoped>
.pagination-list-wrapper{
  width:100%;
  height:auto;
  .top-title-wrapper{
    width:100%;
    height:54px;
    background: #f8f9fb;
    border-radius: 4px 4px 0 0;
    padding-left:16px;
    border-bottom: 1px solid #E8E8E8;
    flex-shrink: 0;
    &.border {
      border: 1px solid #E8E8E8;
    }
    .title-text{
      flex:1;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
    }
  }
  .content-wrapper{
    width:100%;
    height:54px;
    background: white;
    padding-left:16px;
    border-bottom: 1px solid #E8E8E8;
    flex-shrink: 0;
    &.border {
      border-left: 1px solid #E8E8E8;
      border-right: 1px solid #E8E8E8;
    }
    .content-text{
      flex:1;
      color: rgba(0, 0, 0, 0.65);
      word-break: break-all;
      line-height: 20px;
    }
  }
}
::v-deep .el-pagination{
  // position: absolute;
  margin-top:24px;
}
::v-deep .el-pagination__total{
  margin-left: 16px;
}
.el-pagination {
    right:0px;
}
</style>